import styled, { DefaultTheme } from 'styled-components';

import { Spacers } from '@styles/theme';

export const getLabelTypography = (props: { theme: DefaultTheme }) => `
  font-weight: ${props.theme.typography.label.fontWeight};
  line-height: ${props.theme.typography.label.lineHeight};
  letter-spacing: ${props.theme.typography.label.letterSpacing};
  text-transform: ${props.theme.typography.label.textTransform};
  font-size: ${props.theme.typography.label.fontSize};
`;

export const Label = styled.label`
  ${getLabelTypography}
  margin-bottom: ${Spacers.XS}rem;
`;
