'use client';

import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@components/Button';
import { Input } from '@components/Input';
import { TextGroup } from '@components/TextGroup';

import { AuthFormWrapper, AuthInputsWrapper } from './styles/LoginFormContainer';

interface Data {
  email: string;
  password: string;
}

interface LoginFormProps {
  onSubmit: (data: Data) => void;
  hasError: boolean;
}

/**
 * AuthForm component renders a form for user authentication.
 * It includes fields for email and password, and a submit button.
 */
export function LoginForm({ onSubmit, hasError }: LoginFormProps) {
  const { t } = useTranslation('common');
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <AuthFormWrapper onSubmit={handleFormSubmit}>
      <TextGroup
        label={t('login.login')}
        header={t('login.title')}
        paragraph={t('login.description')}
      />
      <AuthInputsWrapper>
        <Input
          type="email"
          id="email"
          placeholder={t('login.enterEmail') + '...'}
          name="email"
          label={t('email')}
          value={formData.email}
          onChange={handleInputChange}
          hasError={hasError}
          errorLabel={t('errors.login')}
        />
        <Input
          type="password"
          id="password"
          name="password"
          placeholder={t('login.enterPassword') + '...'}
          label={t('login.password')}
          value={formData.password}
          onChange={handleInputChange}
          hasError={hasError}
          errorLabel={t('errors.login')}
          required
        />
      </AuthInputsWrapper>
      <Button type="submit" label={t('login.login')} variant={'primary'} />
    </AuthFormWrapper>
  );
}
