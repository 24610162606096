import styled from 'styled-components';

import { Spacers } from '@styles/theme';

export const TextGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PageHeader = styled.h1`
  font-size: ${({ theme }) => theme.typography.heading1.fontSize};
  color: ${({ theme }) => theme.colors.typographyHeadersLight};
  font-weight: ${(props) => props.theme.typography.heading1.fontWeight};
  line-height: ${(props) => props.theme.typography.heading1.lineHeight};
  padding-bottom: ${Spacers.LG}rem;
`;
