'use client';

import { Login } from '@components/Login';

/**
 * LoginPage component renders the login page of the application.
 */
export default function LoginPage() {
  return <Login />;
}
