import styled from 'styled-components';

import { Spacers } from '@styles/theme';

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.typography.heading2.fontSize};
  color: ${({ theme }) => theme.colors.typographyHeadersLight};
  font-weight: ${(props) => props.theme.typography.heading2.fontWeight};
  line-height: ${(props) => props.theme.typography.heading2.lineHeight};
`;

export const TitleContainer = styled.div`
  grid-column: 1 / -1;
  padding-block: ${Spacers.LG}rem;
`;
