import React from 'react';

import { Description, Label } from '@components/Common';

import { TextGroupProps } from './interfaces';

import { PageHeader, TextGroupContainer } from './styles';

/**
 * Text group component that display label, header, description.
 * Used in auth pages. and others components
 */

export const TextGroup = ({ label, header, paragraph }: TextGroupProps) => (
  <TextGroupContainer>
    <Label>{label}</Label>
    <PageHeader>{header}</PageHeader>
    <Description>{paragraph}</Description>
  </TextGroupContainer>
);
