'use client';

import { ButtonProps } from './interfaces';

import { ButtonContainer } from './styles';

/**
 * Button component is a reusable component that renders a button element.
 */
export const Button = ({ label, onClick, disabled = false, variant = 'primary' }: ButtonProps) => {
  return (
    <ButtonContainer onClick={onClick} $disabled={disabled} $variant={variant} disabled={disabled}>
      {label}
    </ButtonContainer>
  );
};
