import styled from 'styled-components';

import { breakpoints } from '@styles/breakpoint';
import { Spacers } from '@styles/theme';

export const LayoutContainer = styled.main`
  height: 100%;
  width: 100%;
  grid-area: content;
  display: flex;
  flex-direction: column;

  ${breakpoints.desktopOrBigger} {
    display: grid;
    grid-template-columns: subgrid;
    grid-column-start: content;
    grid-column-end: right-margin;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ${breakpoints.desktopOrBigger} {
    grid-column: 1 / 5;
  }
`;

export const LogoContainer = styled.div`
  margin-top: ${Spacers.XXL}rem;
  margin-bottom: ${Spacers.XXL}rem;
  display: flex;
  align-self: flex-start;

  ${breakpoints.desktopOrBigger} {
    margin-bottom: unset;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${breakpoints.desktopOrBigger} {
    justify-content: center;
  }
`;

export const ImageContainer = styled.div`
  display: none;

  ${breakpoints.desktopOrBigger} {
    display: flex;
    grid-column: 6 / -1;
    padding: ${Spacers.XS}rem;
    height: 100%;
    width: 100%;
  }
`;

export const IlustrationWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.brand};
  border-radius: ${Spacers.XS}rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
