/* eslint-disable indent */
import styled from 'styled-components';
import { DefaultTheme } from 'styled-components';

import { Spacers } from '@styles/theme';

import { StyledButtonProps } from '@components/Button/interfaces';

const getHoverBackgroundColor = (props: StyledButtonProps & { theme: DefaultTheme }) => {
  if (props.$disabled) {
    return props.$variant === 'primary'
      ? props.theme.colors.backgroundAccent
      : props.theme.colors.backgroundLight;
  }

  return props.$variant === 'primary'
    ? props.theme.colors.backgroundLight
    : props.theme.colors.backgroundDark;
};

const getBackgroundColor = (props: StyledButtonProps & { theme: DefaultTheme }) => {
  if (props.$disabled) {
    return props.$variant === 'primary'
      ? props.theme.colors.backgroundAccent
      : props.theme.colors.backgroundLight;
  }

  return props.$variant === 'primary'
    ? props.theme.colors.backgroundDark
    : props.theme.colors.backgroundLight;
};

const getColor = (props: StyledButtonProps & { theme: DefaultTheme }) => {
  if (props.$disabled) {
    return props.theme.colors.typographyBodyLight;
  }

  return props.$variant === 'primary'
    ? props.theme.colors.typographyHeadersDark
    : props.theme.colors.typographyHeadersLight;
};

const getHoverColor = (props: StyledButtonProps & { theme: DefaultTheme }) => {
  if (props.$disabled) {
    return props.theme.colors.typographyBodyLight;
  }

  return props.$variant === 'primary'
    ? props.theme.colors.typographyHeadersLight
    : props.theme.colors.typographyHeadersDark;
};

export const ButtonContainer = styled.button<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  width: 100%;
  padding: ${Spacers.ML}rem ${Spacers.BASE}rem ${Spacers.SM}rem;
  background-color: ${getBackgroundColor};
  border-radius: 0.5rem;
  border: 1px solid
    ${(props) =>
      props.$disabled ? props.theme.colors.backgroundAccent : props.theme.colors.backgroundDark};
  color: ${getColor};
  font-size: ${(props) => props.theme.typography.action.fontSize};
  font-weight: ${(props) => props.theme.typography.action.fontWeight};
  line-height: ${(props) => props.theme.typography.action.lineHeight};

  &:hover {
    background-color: ${getHoverBackgroundColor};
    color: ${getHoverColor};
  }
`;
