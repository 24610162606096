'use client';

import { ReactNode } from 'react';

import { Grid } from './styles';

/**
 * Component creates a grid layout for the whole project.
 * It uses 12 columns on desktop and 4 columns on mobile and tablets for a responsive design.
 */

interface GridLayoutProps {
  children: ReactNode;
}

export const GridLayout = ({ children }: GridLayoutProps): JSX.Element => {
  return <Grid>{children}</Grid>;
};
