/* eslint-disable max-len */
import { css } from 'styled-components';

/**
 * Grid variables for mobile and desktop layouts
 */

export const GridVariables = css`
  --mobile-columns: 4;
  --mobile-gutter: 1rem;
  --desktop-login-columns: 12;
  --desktop-columns: 6;
  --desktop-column-width: 4.5rem;
  --desktop-gutter: 1.5rem;
  --desktop-max-width: calc(
    (var(--desktop-columns) * var(--desktop-column-width)) +
      ((var(--desktop-columns) - 1) * var(--desktop-gutter))
  );
`;

export const GridTemplateAreas = {
  mobile: `
    'content content content content';
  `,
  desktop: `
    'left-margin content content content content content content right-margin';
  `,
  desktopAuth: `
  'left-margin content content content content content content content content content content content content right-margin';
`,
};

export const GridLayout = {
  mobile: {
    columns: 'repeat(var(--mobile-columns), 1fr)',
    gap: 'var(--mobile-gutter)',
  },
  desktop: {
    columns: '1fr repeat(var(--desktop-columns), var(--desktop-column-width)) 1fr',
    gap: 'var(--desktop-gutter)',
  },
  authDesktop: {
    columns: '1fr repeat(var(--desktop-login-columns), var(--desktop-column-width)) 1fr',
    gap: 'var(--desktop-gutter)',
  },
};
