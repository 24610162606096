'use client';

import { ReactNode } from 'react';

import Logo from '@icons/logo.svg';
import HomescreenIlustration from '@images/Illustration.svg';

import {
  ContentWrapper,
  FormContainer,
  IlustrationWrapper,
  ImageContainer,
  LayoutContainer,
  LogoContainer,
} from './styles';

/**
 * ResponsiveAuthLayout Component
 * Component provides a responsive layout for authentication pages.
 */

interface ResponsiveAuthLayoutProps {
  children: ReactNode;
}

export const ResponsiveAuthLayout = ({ children }: ResponsiveAuthLayoutProps): JSX.Element => {
  return (
    <LayoutContainer className="auth-page">
      <ContentWrapper>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <FormContainer>{children}</FormContainer>
      </ContentWrapper>
      <ImageContainer>
        <IlustrationWrapper>
          <HomescreenIlustration />
        </IlustrationWrapper>
      </ImageContainer>
    </LayoutContainer>
  );
};
