'use client';

import { Label } from '@components/Common';

import { InputProps } from './interfaces';

import { ErrorLabel, InputContainer, InputField } from './styles';

/**
 * Input component renders a styled input field with an optional label and error message.
 */
export const Input = ({
  label,
  onChange,
  placeholder,
  hasError,
  id,
  errorLabel,
  type,
  value,
  name,
}: InputProps) => {
  return (
    <InputContainer>
      <Label htmlFor={id}>{label}</Label>
      {hasError && <ErrorLabel id={`${id}-error`}>{errorLabel}</ErrorLabel>}
      <InputField
        id={id}
        aria-describedby={hasError ? `${id}-error` : undefined}
        type={type}
        placeholder={placeholder}
        $hasError={hasError}
        onChange={onChange}
        value={value}
        name={name}
      />
    </InputContainer>
  );
};
