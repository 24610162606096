'use client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'next/navigation';
import { signIn } from 'next-auth/react';

import { LoginForm } from '@components/LoginForm';

import { ForgotPasswordLink, LoginWrapper } from './styles/LoginContainer';

interface FormData {
  email?: string;
  password?: string;
}

/**
 * Login component that handles user authentication.
 */

export function Login() {
  const { t } = useTranslation('common');
  const router = useRouter();
  const [error, setError] = useState('');

  const handleFormSubmit = async (data: FormData) => {
    const response = await signIn('credentials', {
      email: data.email,
      password: data.password,
      redirect: false,
    });
    if (!response?.error) {
      router.push('/assignments');
      router.refresh();
    } else {
      if (response.status === 401) {
        setError('Your email or password is incorrect');
      }
    }
  };

  return (
    <LoginWrapper>
      <LoginForm onSubmit={handleFormSubmit} hasError={error.length > 0} />
      <ForgotPasswordLink href="/request-reset-password" passHref className="defaultAStyle">
        {t('login.forgotPassword')}
      </ForgotPasswordLink>
    </LoginWrapper>
  );
}
