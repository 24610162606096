import styled from 'styled-components';

import { breakpoints } from '@styles/breakpoint';
import { GridLayout, GridTemplateAreas, GridVariables } from '@styles/GridVariabels';

export const Grid = styled.div`
  ${GridVariables};

  display: grid;
  grid-template-areas: ${GridTemplateAreas.mobile};
  grid-template-columns: ${GridLayout.mobile.columns};
  gap: ${GridLayout.mobile.gap};
  margin-inline: var(--mobile-gutter);

  ${breakpoints.desktopOrBigger} {
    grid-template-columns: ${GridLayout.desktop.columns};
    gap: ${GridLayout.desktop.gap};
    grid-template-areas: ${GridTemplateAreas.desktop};
    margin: 0;
  }

  // For auth components layout should consist of 12 columns in desktop view

  &:has(.auth-page) {
    min-height: 100%;
    ${breakpoints.desktopOrBigger} {
      grid-template-columns: ${GridLayout.authDesktop.columns};
      gap: ${GridLayout.authDesktop.gap};
      grid-template-areas: ${GridTemplateAreas.desktopAuth};
      margin: 0;
    }
  }
`;
