'use client';

import { ReactNode } from 'react';

import { SessionProvider } from 'next-auth/react';

import { ResponsiveAuthLayout } from '@components/Layouts/ResponsiveAuthLayout';

export interface AuthLayoutProps {
  children: ReactNode;
}

/**
 * A layout component for the auth pages.
 */
export default function AuthLayout({ children }: Readonly<AuthLayoutProps>) {
  return (
    <SessionProvider>
      <ResponsiveAuthLayout>{children}</ResponsiveAuthLayout>
    </SessionProvider>
  );
}
