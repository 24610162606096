import { createGlobalStyle, DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
  colors: {
    actionInactive: 'hsl(212, 66%, 85%)',
    actionDefault: 'hsl(213, 65%, 35%)',
    actionHover: 'hsl(213, 65%, 27%)',
    actionActive: 'hsl(213, 66%, 23%)',
    actionError: 'hsl(11, 100%, 43%)',
    typographyHeadersLight: 'hsl(0, 0%, 0%)',
    typographyHeadersDark: 'hsl(0, 0%, 100%)',
    typographyBodyLight: 'hsl(160, 5%, 22%)',
    typographyBodyDark: 'hsl(163, 11%, 87%)',
    backgroundDark: 'hsl(0, 0%, 0%)',
    backgroundDarkOverlay: 'hsla(0, 0%, 0%, 0.2)',
    backgroundLight: 'hsl(0, 0%, 100%)',
    backgroundAccent: 'hsl(163, 11%, 87%)',
    brand: 'hsl(29, 100%, 85%)',
    boxShadow: 'hsl(170, 9%, 88%)',
  },
  typography: {
    heading1: {
      fontWeight: '700',
      lineHeight: '112.5%',
      fontSize: '2rem',
    },
    heading2: {
      fontWeight: '500',
      lineHeight: '133.333%',
      fontSize: '1.125rem',
    },
    body: {
      fontWeight: '400',
      lineHeight: '150%',
      fontSize: '1rem',
    },
    action: {
      fontWeight: '500',
      lineHeight: '150%',
      fontSize: '1rem',
    },
    label: {
      fontWeight: '500',
      lineHeight: '120%',
      fontSize: '0.625rem',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
  },
  dimensions: {
    headerHeight: '3.5rem',
  },
};

// Spacers
export enum Spacer {
  DEFAULT = 1,
}

export enum Spacers {
  XS = Spacer.DEFAULT * 0.5,
  SM = Spacer.DEFAULT * 0.625,
  MD = Spacer.DEFAULT * 0.75,
  ML = Spacer.DEFAULT * 0.875,
  BASE = Spacer.DEFAULT * 1,
  LG = Spacer.DEFAULT * 1.5,
  XL = Spacer.DEFAULT * 3,
  XXL = Spacer.DEFAULT * 3.25,
}

export const GlobalStyles = createGlobalStyle`
html,
body {
  width: 100%;
  height: 100%;
  font-family: var(--font-fsme);
}
button {
  font-family: inherit;
  background: none;
}
input {
  font-family: inherit;
}
h1 {
font-family: var(--font-work-sans);
}
*, *::after, *::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
a:not(.defaultAStyle) {
  color: inherit;
  text-decoration: none;
}
svg {
  display: block;
}
`;
