import styled from 'styled-components';

import { Spacers } from '@styles/theme';

export const AuthFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

export const AuthInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-block: ${Spacers.LG}rem;
  gap: ${Spacers.LG}rem;
`;
